import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactMailchimpSubscribe from 'react-mailchimp-subscribe'
import { checkValidEmail } from '@/utils/checkValidEmail'
import * as styles from './MailchimpSubscribe.styles.scss'

const removeDuplicates = (array) => {
  return array.filter((a, b) => array.indexOf(a) === b)
}

const MailchimpSubscribe = ({ submitLabel, endpoint, successText, email }) => {
  const [inputs, setInputs] = useState({})
  const [errors, setErrors] = useState([])

  const handleInputChange = (event) => {
    event.persist()
    setErrors([])
    setInputs((i) => ({ ...i, [event.target.name]: event.target.value }))
  }

  const emailInput = {
    name: 'EMAIL',
    type: 'email',
    placeholder: 'Enter email address',
    onChange: handleInputChange,
    required: true,
    ...email,
  }

  const handleSubmit = (event, subscribe) => {
    if (event) {
      event.preventDefault()
      const errorMsgs = []

      if (email !== null && !inputs[emailInput.name]) {
        errorMsgs.push('Please fill out required fields.')
      } else if (email !== null && !checkValidEmail(inputs[emailInput.name])) {
        errorMsgs.push('Your email address is invalid.')
      }

      if (!errorMsgs.length) {
        subscribe(inputs)
      } else {
        setErrors(errorMsgs)
      }
    }
  }

  return (
    <div className={styles.MailchimpSubscribe}>
      {endpoint && (
        <ReactMailchimpSubscribe
          url={endpoint}
          render={({ subscribe, status, message }) => {
            return (
              <>
                <div>
                  {status === 'sending' && (
                    <div className={styles.message}>Sending...</div>
                  )}
                  {status === 'error' && (
                    <div className={styles.message}>
                      {typeof message === 'string' ? (
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                      ) : (
                        'Error submitting, please try again'
                      )}
                    </div>
                  )}
                  {status === 'success' && (
                    <div className={styles.message}>{successText}</div>
                  )}
                  {removeDuplicates(errors)
                    .reverse()
                    .map((error, index) => (
                      <div
                        key={`${index}-error-${error}`}
                        className={styles.message}
                      >
                        • {error}
                      </div>
                    ))}
                </div>
                <div className={styles.wrap}>
                  {email !== null && (
                    <input className={styles.input} {...emailInput} />
                  )}
                  <div className={styles.submit}>
                    <button
                      type="submit"
                      onClick={(event) => handleSubmit(event, subscribe)}
                      className={styles.submitBtn}
                    >
                      {submitLabel}
                    </button>
                  </div>
                </div>
              </>
            )
          }}
        />
      )}
    </div>
  )
}

MailchimpSubscribe.defaultProps = {
  submitLabel: 'Submit',
  successText: 'Thank you',
  email: {},
}

MailchimpSubscribe.propTypes = {
  submitLabel: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  successText: PropTypes.node,
  email: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
  }),
}

export { MailchimpSubscribe }
