import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilValue, useRecoilState } from 'recoil'
import { browserState, menuState, headerInverseState } from '@/store'
import { Container } from '@/components/Container'
import { Link } from '@/components/Link'
import { Logo } from '@/components/Logo'

import * as styles from './Header.styles.scss'

const Header = ({ title, nav }) => {
  const { device } = useRecoilValue(browserState)
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const isInverse = useRecoilValue(headerInverseState)

  const handleClickBtnMenu = () => setIsMenuOpen((prevState) => !prevState)

  return (
    <header
      className={classNames(styles.Header, { [styles.isInverse]: !!isInverse })}
    >
      <Container className={styles.container}>
        <div className={styles.inner}>
          <h1>
            <Link to="/" aria-label={title}>
              <Logo isVisible={true} />
            </Link>
          </h1>
          {device === 'desktop' ? (
            <nav className={styles.nav}>
              <ul className={styles.nav__list}>
                {nav.map((item) => (
                  <li key={item.uid} className={styles.nav__item}>
                    <Link
                      to={item.url}
                      target={item.target}
                      download={item.download}
                      className={styles.nav__link}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          ) : (
            <button className={styles.btnMenu} onClick={handleClickBtnMenu}>
              {isMenuOpen ? 'Close' : 'Menu'}
            </button>
          )}
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  nav: [],
}

Header.propTypes = {
  title: PropTypes.string,
  nav: PropTypes.array,
}

export { Header }
