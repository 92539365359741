import React from 'react'
import PropTypes from 'prop-types'
import { gsap, Power3 } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Link } from '@/components/Link'
import { MailchimpSubscribe } from '@/components/MailchimpSubscribe'
import * as styles from './Footer.styles.scss'

gsap.registerPlugin(ScrollToPlugin)

const Footer = ({ columns, nav, newsletter }) => {
  const handleClickBackToTop = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: 0,
      ease: Power3.easeOut,
    })
  }

  return (
    <footer className={styles.Footer}>
      <Container>
        <div className={styles.scrollTop}>
          <button
            onClick={handleClickBackToTop}
            className={styles.scrollTop__btn}
          >
            Back to top
          </button>
        </div>
      </Container>
      <div className={styles.main}>
        <Container>
          <div className={styles.columns}>
            {columns.map((column) => {
              return (
                <div key={column.uid} className={styles.column}>
                  <div className={styles.heading}>{column.heading}</div>
                  {column.body && (
                    <div>
                      <RichText>{column.body}</RichText>
                    </div>
                  )}
                </div>
              )
            })}
            {newsletter?.endpoint && (
              <div className={styles.newsletter}>
                {newsletter.heading && (
                  <div>
                    <div className={styles.newsletter__heading}>
                      {newsletter.heading}
                    </div>
                    <MailchimpSubscribe
                      endpoint={newsletter.endpoint}
                      successText={newsletter.successText}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {!!nav.length && (
            <ul className={styles.nav}>
              {nav.map((item) => {
                return (
                  <li key={item.uid}>
                    <Link
                      to={item.url}
                      target={item.target}
                      download={item.download}
                      className={styles.link}
                    >
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </Container>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  columns: [],
  nav: [],
}

Footer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  nav: PropTypes.arrayOf(PropTypes.object),
  newsletter: PropTypes.object,
}

export { Footer }
