import React, { useRef, useEffect, useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'
import { welcomeState, browserState } from '@/store'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Logo } from '@/components/Logo'
import { useTransition } from './WelcomeOverlay.gsap'
import * as styles from './WelcomeOverlay.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const WelcomeOverlay = ({ heading, children }) => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useRecoilState(welcomeState)
  const { device } = useRecoilValue(browserState)

  useTransition(ref, {
    isVisible: isOpen,
    selectors: {
      bg: `.${styles.bg}`,
      head: `.${styles.head}`,
      bodyText: `.${styles.bodyText}`,
      closeButton: `.${styles.closeButton}`,
      header: `.${styles.header}`,
    },
  })

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useEffect(() => {
    if (!IS_BROWSER) return

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (currentScrollY > 0) {
        handleClose(true)
        window.removeEventListener('scroll', handleScroll)
      }
    }

    if (device === 'desktop') {
      window.addEventListener('scroll', handleScroll, { passive: true })
      handleScroll()
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleClose, device])

  return (
    <div className={styles.WelcomeOverlay} ref={ref}>
      <div className={styles.bg} />
      <button
        onClick={handleClose}
        className={styles.close}
        aria-label="Close"
      />
      <div className={styles.scroll}>
        <div className={styles.header}>
          <Container>
            <div className={styles.header__inner}>
              <Logo isInverse={true} isVisible={true} />
            </div>
          </Container>
        </div>
        <div className={styles.inner}>
          <Container>
            <div className={styles.grid}>
              <div className={styles.head}>
                <div className={styles.heading}>{heading}</div>
              </div>
              <div className={styles.body}>
                <div className={styles.bodyText}>
                  {children && <RichText>{children}</RichText>}
                </div>
                <button onClick={handleClose} className={styles.closeButton}>
                  {device === 'desktop'
                    ? 'Scroll to continue'
                    : 'Tap to continue'}
                </button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

WelcomeOverlay.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
}

export { WelcomeOverlay }
