import React, { useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { transitionState } from '@/store/transitionState'
import { useTransition } from './PageTransition.gsap'
import * as styles from './PageTransition.styles.scss'

const PageTransition = () => {
  const ref = useRef()
  const transition = useRecoilValue(transitionState)

  useTransition(ref, {
    transitionState: transition,
    selectors: { overlay: `.${styles.overlay}` },
  })

  return (
    <div className={styles.PageTransition} ref={ref}>
      <div className={styles.overlay} />
    </div>
  )
}

export { PageTransition }
