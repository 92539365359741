import { atom } from 'recoil'

/**
 * Store browser state here, update in ResizeService
 *
 * @usage
 * const isWelcomeOpen = useRecoilValue(welcomeState)
 */

export const welcomeState = atom({
  key: 'welcomeState', // unique ID (with respect to other atoms/selectors)
  default: true,
})
