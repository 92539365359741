import React, { useRef } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import useCollapse from 'react-collapsed'
import { globalHistory, useLocation } from '@reach/router'
import TransitionService from '@/services/TransitionService'
import { menuState } from '@/store'
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock'
import * as styles from './Menu.styles.scss'

const Menu = ({ navPrimary, navSecondary }) => {
  const scrollRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const { pathname } = useLocation()

  const { getCollapseProps } = useCollapse({
    defaultExpanded: false,
    isExpanded: isMenuOpen,
  })

  useBodyScrollLock(scrollRef, isMenuOpen, { reserveScrollBarGap: true })

  const handleClickLink = (event, url) => {
    event.preventDefault()
    setIsMenuOpen(false)

    setTimeout(() => {
      const currentPath = globalHistory.location.pathname
      if (TransitionService.ready && currentPath !== url) {
        TransitionService.in(url)
          .then(() => {
            navigate(url)
          })
          .catch((error) => console.warn(error))
      }
    }, 500)
  }

  const handleClickClose = () => setIsMenuOpen(false)

  return (
    <div className={styles.Menu}>
      <button
        className={styles.fade}
        style={
          isMenuOpen
            ? { opacity: 1, pointerEvents: 'auto' }
            : { opacity: 0, pointerEvents: 'none' }
        }
        onClick={handleClickClose}
        aria-label="Close Menu"
      />
      <div {...getCollapseProps()}>
        <div className={styles.inner} ref={scrollRef}>
          <nav className={styles.nav}>
            {!!navPrimary.length && (
              <ul className={styles.nav__list}>
                {navPrimary.map((item) => (
                  <li key={item.uid} className={styles.navItem}>
                    <a
                      href={item.url}
                      target={item.target}
                      download={item.download}
                      className={styles.navLink}
                      onClick={(event) => handleClickLink(event, item.url)}
                      aria-current={pathname === item.url ? 'page' : null}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </nav>
          {!!navSecondary.length && (
            <nav className={styles.navSecondary}>
              {navSecondary.length && (
                <ul className={styles.navSecondary__list}>
                  {navSecondary.map((item) => (
                    <li key={item.uid} className={styles.navSecondary__item}>
                      <a
                        href={item.url}
                        target={item.target}
                        download={item.download}
                        onClick={(event) => handleClickLink(event, item.url)}
                        aria-current={pathname === item.url ? 'page' : null}
                        className={styles.navSecondary__link}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  navPrimary: [],
  navSecondary: [],
}

Menu.propTypes = {
  navPrimary: PropTypes.arrayOf(PropTypes.object),
  navSecondary: PropTypes.arrayOf(PropTypes.object),
}

export { Menu }
