import { useEffect } from 'react'
import { gsap, Power1 } from 'gsap'

export const useTransition = (ref, { selectors, isVisible }) => {
  const { bg, head, bodyText, closeButton, header } = selectors

  useEffect(() => {
    const $bg = ref.current.querySelector(bg)
    const $head = ref.current.querySelector(head)
    const $bodyText = ref.current.querySelector(bodyText)
    const $closeButton = ref.current.querySelector(closeButton)
    const $header = ref.current.querySelector(header)

    let tl

    if (isVisible) {
      tl = gsap.timeline({ defaults: { ease: Power1.easeOut } })
      tl.to($bg, { opacity: 1, duration: 0.25 })
      tl.to($head, { opacity: 1, duration: 1.5 }, 1.5)
      tl.to($bodyText, { opacity: 1, duration: 1.5 }, 2)
      tl.to($closeButton, { opacity: 1, duration: 1.5 }, 2.5)
    } else {
      tl = gsap.timeline({
        defaults: { ease: Power1.easeOut, overwrite: true },
      })
      tl.to($head, {
        opacity: 0,
        duration: 0.75,
      })
      tl.to(
        $bodyText,
        {
          opacity: 0,
          duration: 0.75,
        },
        0.15
      )
      tl.to(
        [$closeButton, $header],
        {
          opacity: 0,
          duration: 0.75,
        },
        0.2
      )
      tl.to(
        $bg,
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            gsap.set(ref.current, { pointerEvents: 'none', display: 'none' })
          },
        },
        0.75
      )

      return () => {
        tl.kill()
      }
    }
  }, [bg, head, bodyText, closeButton, header, isVisible, ref])
}
