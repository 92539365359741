exports.storyblokConfig = {
  accessToken: `${process.env.GATSBY_STORYBLOK_API_KEY}`,
  resolveRelations: [
    'thumbs.items',
    'projects.projects',
    'projects.locations',
    'projects.categories',
    'articles.categories',
    'articles.articles',
  ],
  homeSlug: 'home',
  dateFormat: 'YYYY-MM-DD HH:MM',
}
