import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Container.styles.scss'

const Container = ({ children, className, columnWidth }) => (
  <div className={classNames(styles.Container, className)}>
    {typeof columnWidth === 'object' ? (
      <div
        className={classNames({
          [styles.gridRowD]: !!columnWidth.desktop,
          [styles.gridRowM]: !!columnWidth.mobile,
        })}
      >
        <div
          className={classNames({
            [styles[`gridColD${columnWidth.desktop}`]]: !!columnWidth.desktop,
            [styles[`gridColM${columnWidth.mobile}`]]: !!columnWidth.mobile,
          })}
        >
          {children}
        </div>
      </div>
    ) : (
      children
    )}
  </div>
)

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columnWidth: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
  }),
}

export { Container }
