import { linkResolver } from '@/storyblok/helpers/linkResolver'

/**
 * Parse Storyblok Link Data
 */

export const getLink = (options = {}) => {
  const { uid, title, link, target } = options

  if (typeof link === 'object') {
    if (link.linktype === 'story' && link.cached_url) {
      return {
        uid,
        title,
        url: linkResolver(link.cached_url),
        anchor: link.anchor,
      }
    } else if (link.linktype === 'url' && link.url) {
      return {
        uid,
        title,
        url: link.url,
        target: target,
      }
    } else if (link.linktype === 'email' && link.email) {
      return {
        uid,
        title,
        url: `mailto:${link.email}`,
      }
    } else if (link.linktype === 'asset' && link.url) {
      return {
        uid,
        title,
        download: true,
        url: link.url,
      }
    }
  }

  return { uid, title }
}
