import React, { useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import LogoRightSvg from '@/assets/logo-right.inline.svg'
import LogoAngleSvg from '@/assets/logo-angle.inline.svg'
import { useTransition } from './Logo.gsap'
import * as styles from './Logo.styles.scss'

const Logo = ({ isVisible, isInverse }) => {
  const ref = useRef()

  useTransition(ref, {
    isVisible,
    selectors: {
      logoRight: `.${styles.rightSvg}`,
      logoAngle: `.${styles.angleSvg}`,
    },
  })

  return (
    <span
      className={classNames(styles.Logo, { [styles.isInverse]: isInverse })}
      ref={ref}
    >
      <LogoRightSvg className={styles.rightSvg} />
      <LogoAngleSvg className={styles.angleSvg} />
    </span>
  )
}

Logo.propTypes = {
  isVisible: PropTypes.bool,
  isInverse: PropTypes.bool,
}

export { Logo }
