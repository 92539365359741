import { atom } from 'recoil'

/**
 * Store browser state here, update in ResizeService
 *
 * @usage
 * const isInverse = useRecoilValue(headerInverseState)
 */

export const headerInverseState = atom({
  key: 'headerInverseState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
