// extracted by mini-css-extract-plugin
export var Container = "P_cn";
export var aniBgLoading = "P_f";
export var aniSpin = "P_d";
export var gridColD1 = "P_cr";
export var gridColD10 = "P_cC";
export var gridColD11 = "P_cD";
export var gridColD12 = "P_cF";
export var gridColD2 = "P_cs";
export var gridColD3 = "P_ct";
export var gridColD4 = "P_cv";
export var gridColD5 = "P_cw";
export var gridColD6 = "P_cx";
export var gridColD7 = "P_cy";
export var gridColD8 = "P_cz";
export var gridColD9 = "P_cB";
export var gridColM1 = "P_cG";
export var gridColM2 = "P_cH";
export var gridRowD = "P_cp";
export var gridRowM = "P_cq";