import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '@/storyblok/helpers/getLink'
import faviconImg from '@/assets/favicon.png'
import faviconDarkmodeImg from '@/assets/favicon-darkmode.png'
import { getRichText } from '@/storyblok/helpers/getRichText'

/**
 * Pull in Storyblok config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: allStoryblokEntry(filter: { slug: { eq: "global" } }) {
          edges {
            node {
              id
              full_slug
              content
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const storyblokConfig = get(data, 'global.edges')
    ? JSON.parse(data.global.edges[0].node?.content)
    : {}

  const navPrimary = []
  storyblokConfig.nav_primary?.forEach((item) => {
    navPrimary.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const navSecondary = []
  storyblokConfig.nav_secondary?.forEach((item) => {
    navSecondary.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const siteTitle = storyblokConfig.title || gatsbyConfig.title

  const footerColumns = []
  storyblokConfig.footer_columns?.forEach((item) => {
    footerColumns.push({
      uid: item._uid,
      heading: item.heading,
      body: getRichText(item.body),
    })
  })

  const footerNav = []
  storyblokConfig.footer_nav?.forEach((item) => {
    footerNav.push(
      getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
      })
    )
  })

  const newsletter = {
    endpoint: storyblokConfig.newsletter_endpoint,
    successText: storyblokConfig.newsletter_confirmation,
    heading: storyblokConfig.newsletter_heading,
  }

  return {
    metadata: {
      title: siteTitle,
      description: storyblokConfig.description || gatsbyConfig.description,
      subtitle: storyblokConfig.subtitle || gatsbyConfig.subtitle,
      image: get(storyblokConfig, 'image.filename') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: faviconDarkmodeImg || faviconImg,
    },
    header: {
      nav: navPrimary,
      title: siteTitle,
    },
    menu: {
      navPrimary,
      navSecondary,
    },
    footer: {
      columns: footerColumns,
      nav: footerNav,
      newsletter,
    },
    acknowledgement: {
      heading: storyblokConfig.acknowledgement_heading,
      children: getRichText(storyblokConfig.acknowledgement_body),
    },
  }
}
