import { gsap, Power1 } from 'gsap'
import { useEffect } from 'react'

export const useTransition = (ref, { selectors, isVisible }) => {
  const { logoRight, logoAngle } = selectors

  useEffect(() => {
    if (isVisible) {
      const $logoRight = ref.current.querySelector(logoRight)
      const $logoAngle = ref.current.querySelector(logoAngle)

      gsap.to($logoRight, {
        opacity: 1,
        duration: 1,
        delay: 0.5,
        ease: Power1.easeOut,
      })
      gsap.to($logoAngle, {
        opacity: 1,
        duration: 1,
        delay: 1.25,
        ease: Power1.easeOut,
      })
    }
  }, [ref, logoRight, logoAngle, isVisible])
}
