import { useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export const useBodyScrollLock = (ref, isActive, options = {}) => {
  useEffect(() => {
    if (isActive) {
      disableBodyScroll(ref.current, { ...options })
    } else {
      enableBodyScroll(ref.current)
    }
  }, [ref, isActive, options])
}
